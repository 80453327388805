.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("../assets/images/teams-bkg.png");
    background-size: cover;
    background-position: center;
}

.cardsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.title {
    height: 15vh;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2vw;
}

.assignedCard {
    width: 15% ;
}

.cardsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    position: relative;
    justify-items: center;
    align-items: center;
    padding: 20px;
}

.teamCard {
    perspective: 1000px;
    cursor: pointer;
    width: 194px;
    height: 288px;
    position: relative;
    z-index: 1;
    transition: transform 1s, width 1s, height 1s;
}

.teamCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1.2s;
    transform-style: preserve-3d;
}

.teamCard.flipped .teamCardInner {
    transform: rotateY(180deg);
}

.teamCard.centered {
    z-index: 10;
}

.cardsGrid.revealed .teamCard:not(.centered) {
    opacity: 0.3;
    transition: opacity 0.8s;
}

.teamCardFront, .teamCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.teamCardFront {
    background-color: #bbb;
    color: black;
}

.teamCardBack {
    background-color: #ff4f4f;
    color: black;
    transform: rotateY(180deg);
}

.teamCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teamCard.placeholder {
    visibility: hidden;
}

.cardRevealed {
    width: 20%;
}

@media only screen and (max-width: 600px) {
    .teamCard {
        width: 135px;
        height: 200px;
        transition: transform 1s, width 1s, height 1s;
    }

    .assignedCard {
        width: 50% ;
    }

    .title {
        font-size: 6vw;
        height: 30vh;
    }

    .cardRevealed {
        width: 50%;
    }
}
