.mainContainer{
    width: 100vw;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    background-color: white;

    position: relative;

    overflow: hidden;
}

.formContainer {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-family: 'Edenred', sans-serif;
    margin-right: 10vw;
    margin-left: 10vw;
    background-color: white;
    border-radius: 50px;
    position: relative;
    z-index: 2;
}

.formTitle {
    margin-top: 5%;
    margin-bottom: 0;
    text-align: center;
}

.formSubtitle {
    margin-bottom: 5%;
    font-weight: normal;
    text-align: center;
    width: 70%;
}

.formSubtitle1 {
    font-weight: normal;
    text-align: left;
    width: 75%;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.errorMessage {
    color: #d32f2f;
    margin-top: 0.5vh;
    margin-bottom: 0;
    padding-left: 1rem;
    font-size: 0.8vw;

    min-height: 24px;
}

.formSection {
    text-align: left;
    width: 100%;
}

.backgroundImgUp {
    position: absolute;
    z-index: 1;
    top: 0vh;
    width: 100%;
}

.backgroundImgDown {
    position: absolute;
    z-index: 1;
    bottom: 0vh;
    width: 100%;
}

@media only screen and (max-width: 600px) {

    .mainContainer {
        min-height: 80vh;
    }

    .formContainer {
        width: 95vw;
    }

    .errorMessage {
        font-size: 3vw;
    }

    .formSubtitle {
        margin-bottom: 15%;
    }

    .formSubtitle1 {
        font-size: 3vw;
        text-align: left;
        width: 80%;
    }

    .checkboxLabel{
        font-size: 3vw;
    }

    .formSection {
        font-size: 4vw;
    }
}