.mainContainer {
    width: 100vw;
    height: 8vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url("../assets/images/footer-bkg.svg");
    background-size: 100%;
    background-repeat: no-repeat;

    color: white;
}

.text {

}

@media only screen and (max-width: 600px) {
    .mainContainer {
        width: 100vw;
        height: 20vw;
        background-image: url("../assets/images/footer-bkg.svg");
        background-size: 190%;
        background-repeat: no-repeat;
    }

    .text {
        font-size: 3vw;
    }
}
