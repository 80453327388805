body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Edenred', sans-serif;
}

/* Define Edenred Regular */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Define Edenred Light */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred_light.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

/* Define Edenred Light Italic */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred_light_italic.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: italic;
}

/* Define Edenred Medium */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred_medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

/* Define Edenred Bold */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred_bold.otf') format('opentype');
  font-weight: bold; /* Bold */
  font-style: normal;
}

/* Define Edenred Black */
@font-face {
  font-family: 'Edenred';
  src: url('./assets/fonts/edenred_black.otf') format('opentype');
  font-weight: 900; /* Black */
  font-style: normal;
}