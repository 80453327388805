.mainContainer {
    width: 100vw;
    height: 30vh;

    background-image: url("../assets/images/header-bkg.png");
    background-size: 100%;
    background-repeat: no-repeat;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    z-index: 0;
}

.title {
    font-size: 3.4vw;
    margin-top: 1%;
    margin-bottom: 0;
    font-weight: lighter;
}

.subtitle {
    font-size: 2.5vw;
    margin-top: 1%;
}

.edenredLogo {
    position: absolute;
    z-index: 1;
    width: 9vw;

    left: 3vw;
    top:3vh;
}

.weareoneLogo {
    position: absolute;
    z-index: 1;
    width: 12vw;

    right: 5vw;
    bottom: 50%;
    transform: translate(0,50%);
}

.gradientText {
    background-image: linear-gradient(90deg, #e31e23 0 25%, #e51575 25% 100%);
    background-clip: text;
    color: transparent;
}

@media only screen and (max-width: 600px) {

    .mainContainer {
        background-size: 175%;
        height: 20vh;
    }

    .title {
        font-size: 5.5vw;
    }

    .subtitle {
        font-size: 4.2vw;
    }

    .edenredLogo {
        width: 13vw;

        left: 5vw;
        top:3vh;
    }

    .weareoneLogo {
        width: 16vw;

        right: 3.5vw;
    }
}