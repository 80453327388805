
.menuButton{
    color: #EF4231 !important;
    filter: grayscale(100%) !important;;
    text-decoration: none !important;;
}

.menuButton:hover, .menuButton.active{
    filter: grayscale(0%) !important;;
    color: #EF4231 !important;
}
